dt {
  color: $mid-blue;
  flex-grow: 1;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.2rem;
}

.specs {
  background-color: rgba($white-grey, 0.5);
  color: $dark-blue;
  flex-grow: 1;
  padding: 1rem 2rem;

  &__row {
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 1.5rem;
    padding: 0;

    &:not(:first-child) {
      border-top: 1px solid rgba($dark-blue-grey, 0.1);
    }
  }

  &__section:last-child .specs__row:first-child {
    border-top: 1px solid rgba($dark-blue-grey, 0.1);
  }
}

.main {
  color: $dark-blue;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  &__status_col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    dt:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
}

.alert {
  align-items: flex-start;
  background-color: $white;
  border: 1px solid transparent;
  border-left: 4px solid;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  max-width: 100%;
  min-width: 27rem;
  padding: 0.75rem 1.25rem;
  position: relative;
}
